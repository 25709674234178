import React from 'react';
import Helmet from 'react-helmet';
import '../../assets/scss/init.scss';
import favicon from "../../assets/favicon.png";
import CookiePopUp from "../CookiePopUp/index";

class Layout extends React.Component {
    render() {
        const { children } = this.props;

        return (
            <div className="layout">
                <Helmet defaultTitle="cosee Blog"
                        link={[
                            {rel: 'shortcut icon', type: 'image/x-icon', href: `${favicon}` },
                        ]}/>
                <CookiePopUp/>
                {children}
            </div>
        );
    }
}

export default Layout;
