import React from "react";
import CookiePopUp from "react-cookie-consent";
/* eslint-disable */

class CookieConsent extends React.Component {
    render() {
        return (
            <div>
                <CookiePopUp
                    location="bottom"
                    buttonText="Akzeptieren"
                    enableDeclineButton={true}
                    declineButtonText="Ablehnen"
                    cookieName="cookie_control_consent"
                    flipButtons={true}
                    containerClasses="CookiePopUp-content"
                    buttonClasses="CookiePopUp-button"
                    declineButtonClasses="CookiePopUp-button CookiePopUp-decline"
                    disableButtonStyles={true}
                >
                    <strong>Verwendung von Cookies: </strong>
                    Ich bin damit einverstanden, dass die Firma cosee GmbH meinen Webseitenbesuch zum Zwecke der
                    Auswertung speichert. Wir werden diese Informationen dazu verwenden, die
                    Seite noch attraktiver zu gestalten, Inhalte anzupassen und Angebote zu verbessern und entsprechend
                    auszurichten. Sie können Ihre Einwilligung jederzeit formlos mit Wirkung für die Zukunft widerrufen.
                    Weitere Informationen können Sie unserer <a target="_blank"
                                                                href="https://static.cosee.biz/documents/Privacy_Policy_Website_cosee.pdf"
                                                                rel="noopener noreferrer"
                                                                className="CookiePopUp-link">Datenschutzerklärung</a> entnehmen.
                </CookiePopUp>
            </div>
        )
    }
}

export default CookieConsent
